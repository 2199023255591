import { useRouteError, isRouteErrorResponse } from "react-router-dom";

export class NotFoundError extends Error {
    constructor(message = "Not Found") {
        super(message);
        this.name = "NotFoundError";
    }
}

export class UnauthorizedError extends Error {
    constructor(message = "Unauthorized") {
        super(message);
        this.name = "UnauthorizedError";
    }
}

export class NetworkError extends Error {
    constructor(message = "Network Error") {
        super(message);
        this.name = "NetworkError";
    }
}

export class GraphQLError extends Error {
    constructor(message = "GraphQL Error") {
        super(message);
        this.name = "GraphQLError";
    }
}

function RouterErrorBoundary() {
    const routeError = useRouteError();

    if (isRouteErrorResponse(routeError)) {
        if (!routeError.statusText) {
            return null;
        }

        switch (routeError?.statusText) {
            case "Unauthorized":
                throw new UnauthorizedError();
            case "Network Error":
                throw new NetworkError();
            default:
                throw new NotFoundError();
        }
    }
}

export default RouterErrorBoundary;
