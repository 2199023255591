import { RouterProvider as ReactRouterDomRouterProvider } from "react-router-dom";
import router from "./routes";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorTemplate } from "@bespeak/ui";
namespace RouterProvider {}

export function RouterProvider() {
    return (
        <ErrorBoundary FallbackComponent={ErrorTemplate}>
            <ReactRouterDomRouterProvider router={router} />
        </ErrorBoundary>
    );
}
