export function mapErrors(message: string, name: string) {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    let errorName = "general-error";
    let errorMessage = "not-found";

    //This isn't used at the moment, but might prove useful in the future
    switch (name) {
        case "GraphQLError":
        case "NetworkError":
            errorName = "network-error";
            break;
        case "UnauthorizedError":
            errorName = "authorization-error";
            break;
        default:
            errorName = "general-error";
            break;
    }

    if (message.includes("Variable 'programId' has an invalid value")) {
        errorMessage = "invalid-program";
    }

    if (
        message.includes("Denied") &&
        message.includes("Exception while fetching data")
    ) {
        errorMessage = "authorization-error";
    }

    return {
        errorName,
        errorMessage,
    };
}

export default mapErrors;
