import { DefaultHeader } from "@bespeak/app/src/components/organisms/DefaultHeader/DefaultHeader";
import { Center, Spacer } from "@bespeak/ui";
import { useTranslation } from "react-i18next";
import { Button } from "@bespeak/ui";
import mapErrors from "./mapErrors";
export type NotFoundProps = {
    error?: Error;
};

export const ErrorTemplate = ({ error }: NotFoundProps) => {
    const { t } = useTranslation("common", {
        keyPrefix: "not-found",
    });

    if (!error) {
        return;
    }

    const { errorMessage } = mapErrors(error.message, error.name);

    return (
        <section data-test-id="not-found-page">
            <DefaultHeader
                headerHeight={DefaultHeader.Height.Sm}
                preTitle={t("title", "Oeps...")}
                heading={t(errorMessage, "Die pagina is niet gevonden")}
            />
            <Spacer size="3xl" />
            <Center>
                <Button
                    variant="text"
                    onClick={() => window.open("/", "_self")}
                >
                    {t("backToHome", "Klik hier om naar de homepage te gaan")}
                </Button>
            </Center>
        </section>
    );
};
