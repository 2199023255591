import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useGetAuthenticatedUserAuthoritiesQuery } from "@bespeak/apollo";
import type { Scope } from "@/store/permissions";
import { usePermissionsStore } from "@/store/permissions";
import useAuthoritiesError from "./useAuthoritiesError";

namespace PermissionsProvider {
    export interface Props {
        children: ReactNode;
    }
}

export function PermissionsProvider(props: PermissionsProvider.Props) {
    const { t } = useTranslation();

    const { isAuthenticated } = useAuth();

    const [authoritiesError, setAuthoritiesError] = useAuthoritiesError();

    useGetAuthenticatedUserAuthoritiesQuery({
        onCompleted: (data) => {
            usePermissionsStore.setState({
                scopes: data.getAuthoritiesForAuthenticatedUser as Scope[],
                ready: true,
            });
        },
        onError: (error) => {
            setAuthoritiesError(error);
            throw new Error(error.message);
        },
        skip: !isAuthenticated,
    });

    if (authoritiesError) {
        console.error(authoritiesError.message);

        return (
            <div
                style={{
                    padding: "3rem",
                    position: "absolute",
                    inset: 0,
                    display: "grid",
                    placeItems: "center",
                    background: "white",
                }}
            >
                <div>
                    <div>
                        {t(
                            "error.authorities",
                            "Er is een probleem opgetreden met het autorisatieproces. Zorg ervoor dat je de benodigde permissies hebt en probeer het opnieuw.",
                        )}
                    </div>
                    <div style={{ opacity: 0.4 }}>
                        {authoritiesError.message}
                    </div>
                </div>
            </div>
        );
    }

    return props.children;
}
